import 'vanilla-cookieconsent';
window.cookieConsentConfiguration = {"current_lang":"en","autoclear_cookies":true,"cookie_name":"cc_cookie_demo2","cookie_expiration":365,"force_consent":true,"gui_options":{"consent_modal":{"layout":"cloud","position":"bottom center","transition":"slide"},"settings_modal":{"layout":"bar","position":"left","transition":"slide"}},"languages":{"en":{"consent_modal":{"title":"Hello traveller, it's cookie time!","description":"Our website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <a href=\"#privacy-policy\" class=\"cc-link\">Privacy policy</a>","primary_btn":{"text":"Accept all","role":"accept_all"},"secondary_btn":{"text":"Preferences","role":"settings"},"revision_message":"<br><br> Dear user, terms and conditions have changed since the last time you visisted!"},"settings_modal":{"title":"Cookie settings","save_settings_btn":"Save current selection","accept_all_btn":"Accept all","reject_all_btn":"Reject all","close_btn_label":"Close","cookie_table_headers":[{"col1":"Name"},{"col2":"Domain"},{"col3":"Expiration"}],"blocks":[{"title":"Cookie usage","description":"description"},{"title":"Strictly necessary cookies","description":"description","toggle":{"value":"necessary","enabled":true,"readonly":true}},{"title":"Analytics & Performance cookies","description":"description","toggle":{"value":"analytics","enabled":false,"readonly":false},"cookie_table":[{"col1":"^_ga","col2":"yourdomain.com","col3":"description ...","is_regex":true},{"col1":"_gid","col2":"yourdomain.com","col3":"description ..."},{"col1":"cc_youtube","col2":"yourdomain.com","col3":"Cookie set by iframemanager"}]},{"title":"More information","description":"description ..."}]}}}}
window.cookieConsent = initCookieConsent();
window.cookieConsent.run(window.cookieConsentConfiguration);
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});